<template>
  <div class="page-container audits-page">
    <portal to="page-name">Auditoria</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Auditoria</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-2 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.filters.startDate.$error}">
            <label class="form-label">Data início</label>
            <div class="input-group">
              <dx-input-date
                id="appointment-patient-birth-date"
                class="form-input text-center"
                v-model="filters.startDate"
                @blur="$v.filters.startDate.$touch()"
              />
            </div>
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.filters.endDate.$error}">
            <label class="form-label">Data término</label>
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="filters.endDate"
              @blur="$v.filters.endDate.$touch()"
            />
          </div>
          <div class="column col-5 col-sm-12 form-group"
               :class="{'has-error': $v.filters.event.$error}">
            <label class="form-label">Evento</label>
            <select class="form-select"
                    v-model="filters.event"
                    @blur="$v.filters.event.$touch()">
              <option value="">[Selecione o evento]</option>
              <option v-for="(item, i) in events"
                      :value="item.code" :key="i">{{ item.name }}</option>
            </select>
          </div>
          <div class="column col-auto col-sm-12 mb-2" style="align-self: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left" @click="find">
              <fa-icon :icon="['fal', 'search']"/>Pesquisar
            </button>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Auditoria</p>
            <p class="empty-subtitle">
              Nenhum registro encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th>Data da alteração</th>
              <th>Usuário responsável</th>
              <th>Perfil</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td>{{ item.dueDate | date('DD/MM/YYYY') }}</td>
              <td>{{ item.entity.name }}</td>
              <td>Administrador</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../data/validators';

export default {
  data() {
    return {
      path: '/audits',
      debounceTimeout: null,
      loading: false,
      events: [
        { code: 'a', name: 'Abertura de mais de um caixa no mesmo dia' },
        { code: 'a', name: 'Alteração de agendamento passado' },
        { code: 'b', name: 'Alteração de permissões' },
        { code: 'c', name: 'Autorização de desconto' },
        { code: 'd', name: 'Autorização de retorno fora do prazo' },
        { code: 'e', name: 'Cancelamento de fatura' },
        { code: 'f', name: 'Exclusão de agendamentos' },
        { code: 'g', name: 'Remoção de procedimentos da conta' },
      ],
      filters: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        event: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { required, date },
        endDate: { required, date },
        event: { required },
      },
    };

    if (this.filters.startDate && moment(this.filters.startDate).isValid()) {
      rules.filters.endDate = {
        required,
        date,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  methods: {
    find() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return;
      }

      this.loading = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        type: 'expense',
        transfer: true,
      };

      this.$http.get('/financial-transactions', { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .audits-page {
  }
</style>
